<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Scanner</h1>
        </b-container>
    </div>
</template>
  
<script>
    export default {
        metaInfo: {
            title: 'Scanner',
        },
        components: {},
        data() {
            return {
                event: {}
            }
        },
        computed: {},
        methods: {
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>